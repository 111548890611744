<template>
  <div
    ref="personRef"
    class="person"
  >
    <div class="person__header">
      <div
        class="title-1"
        v-text="$t('person.person', { number: personNumber })"
      />

      <basic-text-button
        v-show="
          !isDisabled &&
          ($props.index > 0 || model.personData.dateOfBirth || model.personData.gender || model.personData.firstName)
        "
        :label="$t('product.actions.remove')"
        @click="$emit('delete')"
      >
        <template #trailing>
          <the-icon
            art="solid"
            name="trash-can-xmark"
          />
        </template>
      </basic-text-button>
    </div>

    <div class="person__fields">
      <basic-date-input
        v-model="model.personData.dateOfBirth"
        :name="`dateOfBirth_${personNumber}`"
        :disabled="isDisabled"
        :label="birthdateText"
        :placeholder="$t('form.dateHint')"
        :v="v.personData.dateOfBirth"
      />

      <basic-gender
        v-model="model.personData.gender"
        :name="`gender_${personNumber}`"
        :age="DateTimeUtil.getAge(model.personData.dateOfBirth)"
        :readonly="isDisabled"
        :v="v.personData.gender"
      />

      <basic-text-input
        v-model="model.personData.firstName"
        :name="`firstName_${personNumber}`"
        :disabled="isDisabled"
        :label="firstnameText"
        :placeholder="$t('person.person', { number: personNumber })"
        :v="v.personData.firstName"
      />

      <basic-text-input
        v-if="model.personData.lastName"
        v-model="model.personData.lastName"
        :name="`lastName_${personNumber}`"
        :disabled="isDisabled"
        :label="$t('person.lastname')"
        :v="v.personData.lastName"
      />
    </div>

    <template v-if="collectiveGroups.length > 0">
      <basic-select
        v-model="model.collectivePersonGroup"
        :name="`collectivePersonGroup_${personNumber}`"
        :disabled="isDisabled"
        :label="$t('person.collective.group')"
        :options="collectiveGroups"
        :v="v.collectivePersonGroup"
      />
    </template>

    <template v-if="model.personData.gender === GENDER.UNBORN">
      <basic-message :content="$t('person.unbornMessage')" />
    </template>

    <template v-else-if="!isDisabled">
      <div class="person__immigrant">
        <div class="immigrant">
          <div class="immigrant__toggle">
            <basic-toggle
              v-model="model.immigrant"
              :name="`immigrant_${personNumber}`"
              placement="left"
            >
              <span
                class="title-2"
                v-text="$t('screen.welcome.immigrant.title')"
              />

              <the-icon
                v-tooltip="{ trigger: 'click', content: $t('screen.welcome.immigrant.tooltip') }"
                art="regular"
                html-tooltip
                name="question-circle"
                prevent-input-toggle
              />
            </basic-toggle>
          </div>
        </div>
        <template v-if="model.immigrant">
          <basic-date-input
            v-model="model.preInsurer.currentRegistration"
            :name="`currentRegistration_${personNumber}`"
            :supporting-text="$t('form.dateHint')"
            :label="$t('screen.personalData.preInsurer.currentRegistration')"
            :v="v.preInsurer.currentRegistration"
          />

          <!--
          <basic-message :content="$t('screen.welcome.immigrant.message.text')" />
          -->
        </template>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import omit from 'lodash/omit'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { ValidatorsUtil } from '@/utils/Validators'

import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import { DateTimeUtil } from '@/utils/DateTime'

import {
  BasicDateInput,
  BasicGender,
  BasicMessage,
  BasicSelect,
  BasicTextButton,
  BasicTextInput,
  BasicToggle,
} from '@/components/Basic'

import { GENDER, LOGIN_OPEN_MODAL, ON_SCREEN_FOCUS_DELAY, ROUTE_URL_PARAMS } from '@/config/constants'
import { dateOfBirth, firstName } from '@/validations/components'
import { events$ } from '@/services/index.js'
import { EVENT_PERSONS_FORM_DISPLAYED, SOURCE } from '@/config/events.js'

// HOOKS
const { isWww } = useApplication()
const { t } = useI18n()

// INIT
defineEmits(['delete'])
const model = defineModel({ type: Object })
const props = defineProps({
  contractStartDate: {
    type: String,
    required: true,
  },

  hasImmigrationDateCheck: {
    type: Boolean,
    default: false,
  },

  index: {
    type: Number,
    required: true,
  },

  isExistingCustomer: {
    type: Boolean,
    default: false,
  },

  personGroups: {
    type: Array,
    default: () => [],
  },

  routeParams: {
    type: Object,
    default: () => {},
  },

  source: {
    type: String,
    default: SOURCE.CONFIGURATOR,
  },
})

// DATA
const personRef = ref()

// COMPUTED
const birthdateText = computed(() => {
  return isUnborn.value ? t('person.estimatedBirthdate') : t('person.birthdate')
})

const collectiveGroups = computed(() => {
  return props.personGroups.map(g => {
    return {
      value: g.groupNumber,
      label: g.name,
    }
  })
})

const firstnameText = computed(() => {
  return isUnborn.value ? t('person.provisionalFirstname') : t('person.firstname')
})

const isDisabled = computed(() => {
  return props.isExistingCustomer && !!model.value.partnerNumber
})

const isUnborn = computed(() => {
  return model.value.personData.gender === GENDER.UNBORN
})

const personNumber = computed(() => {
  return props.index + 1
})

// METHODS
async function checkRouteParams() {
  if (isWww.value) return
  if (!props.routeParams) return

  const { personId, fieldName } = props.routeParams

  if (personId === model.value.personId) {
    await nextTick()

    if (fieldName === ROUTE_URL_PARAMS.IMMIGRANT) {
      model.value[fieldName] = true

      await nextTick()
      const target = personRef.value.querySelector(`[name=currentRegistration_${personNumber.value}]`)

      window.setTimeout(() => {
        target?.focus()
        target?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        })
      }, ON_SCREEN_FOCUS_DELAY)
    }
  }
}

function resetImmigrant(value) {
  model.value.preInsurer = model.value.preInsurer || {}

  if (value) {
    model.value.preInsurer.preInsurer = false
    model.value.immigrant = true
  } else {
    model.value.preInsurer.currentRegistration = null
    model.value.preInsurer.preInsurer = true
    model.value.immigrant = false
  }
}

// VALIDATION
const v = useVuelidate(
  computed(() => ({
    collectivePersonGroup: {
      required: requiredIf(function () {
        return partnerStore.partner.collectiveContractConfig?.personGroups?.length > 0
      }),
    },
    preInsurer: {
      currentRegistration: {
        required: requiredIf(() => model.value.immigrant),
        date: ValidatorsUtil.isDate,
        dateRange: date => {
          if (!props.hasImmigrationDateCheck) return true

          return ValidatorsUtil.isInDateRange(
            DateTimeUtil.getMinContractStartDate(),
            DateTimeUtil.getMaxContractStartDate()
          )(date)
        },
      },
    },
    personData: {
      firstName: {
        ...omit(firstName, ['required']),
      },
      lastName: {},
      dateOfBirth: {
        ...dateOfBirth,
        dateRange: function (value) {
          return model.value.personData.gender !== GENDER.UNBORN || DateTimeUtil.isInFuture(value)
        },
      },
      gender: { required },
    },
  })),
  model.value,
  { $autoDirty: true }
)

// WATCHERS
watch(
  () => model.value.personData.gender,
  value => {
    if (value === GENDER.UNBORN) {
      resetImmigrant(false)
    }
  }
)

watch(
  () => model.value.immigrant,
  value => {
    resetImmigrant(value)
  }
)

watch(
  () => model.value.preInsurer?.currentRegistration,
  () => {
    if (model.value.preInsurer?.currentRegistration) {
      model.value.kvgContractStartDate = model.value.preInsurer.currentRegistration
    }
  }
)

// LIFECYCLE HOOKS
onMounted(() => {
  // if login-stuff is not present, fire the normal persons-funnel-event
  if (!localStorage.getItem(LOGIN_OPEN_MODAL)) {
    events$.emit(EVENT_PERSONS_FORM_DISPLAYED, { source: props.source })
  }

  checkRouteParams()
})
</script>

<style scoped>
.person__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 var(--dotcom-responsive-gutter-responsive);
}

.person {
  .person__immigrant {
    display: flex;
    flex-direction: column;
    gap: var(--dotcom-responsive-spacing-res-xxxs);

    .immigrant {
      display: flex;
      flex-direction: column;

      &__toggle {
        display: flex;
        flex-direction: row;
      }
    }

    .contract-date__tooltip {
      cursor: pointer;
      margin: auto 10px;
    }
  }
}
</style>
